import Container from 'components/elements/containers/container'

export default function FourOhFour() {
  return (
    <div className="flex h-screen flex-grow flex-col items-center justify-center">
      <Container extraClasses="flex flex-col  ">
        <div className="flex flex-row justify-center">
          <div className="border-r-2 border-tella-blue-300 text-4xl font-bold">
            <span className="mr-4">404</span>
          </div>
          <div className="flex items-center justify-center">
            <span className="ml-4">Page not found</span>
          </div>
        </div>
      </Container>
    </div>
  )
}
